import * as React from "react"
import Header from "./Header/Header"
import Footer from "./Footer/Footer"
import "./layout.css"
import { Helmet } from "react-helmet"
import { graphql, useStaticQuery } from "gatsby"
import { init, trackPages } from "insights-js"

function Layout({ pageTitle, children }) {
    const data = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    title
                }
            }
        }
    `)

    init('DTBVR4LIQOyYDnC6')
    trackPages()

    return (
        <>
            <Helmet>
                {
                    pageTitle ? (
                        <title>{pageTitle} | {data.site.siteMetadata.title}</title>
                    ) : (
                        <title>{data.site.siteMetadata.title}</title>
                    )
                }
                <meta charSet="UTF-8" />
                <html lang="en" />
            </Helmet>
            <Header />
            <main>        
                {children}
            </main>
            <Footer />
        </>
    )
}

export default Layout