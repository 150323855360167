import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import * as FooterStyles from "./Footer.module.css"

function Footer() {
    let date = new Date()
    let year = date.getFullYear()

    return (
        <footer>
            <div className={FooterStyles.container}>
                <div className={FooterStyles.linksContainer}>
                    <h3>Useful links</h3>
                    <ul className={FooterStyles.linkList}>
                        <li><Link to="/">Home</Link></li>
                        <li><Link to="/about/">About</Link></li>
                        <li><Link to="/services/eye-examinations/">Eye examinations</Link></li>
                        <li><Link to="/services/contact-lenses/">Contact lenses</Link></li>
                        <li><Link to="/services/neuro-optometry/">Neuro-optometry</Link></li>
                        <li><Link to="/contact/">Get in touch</Link></li>
                        
                    </ul>
                </div>
                <div className={FooterStyles.accreditationsContainer}>
                    <a href="https://www.college-optometrists.org/" target="_blank" rel="noopener noreferrer"><StaticImage src="../../../images/CollegeOptometrists.jpg" alt="" height={70} /></a>
                    <a href="https://www.aiovision.org/" target="_blank" rel="noopener noreferrer"><StaticImage src="../../../images/AIO.jpg" alt="AIO logo" height={70} /></a>
                    <a href="https://www.aop.org.uk/" target="_blank" rel="noopener noreferrer"><StaticImage src="../../../images/AOP.jpg" alt="AOP logo" height={70} /></a>
                    <a href="https://noravisionrehab.org/" target="_blank" rel="noopener noreferrer"><StaticImage src="../../../images/NORA logo.png" alt="NORA logo" height={70} /></a>
                    <a href="https://optical.org/" target="_blank" rel="noopener noreferrer"><StaticImage src="../../../images/GOC.png" alt="GOC logo" height={70} /></a>
                    <a href="https://www.covd.org/" target="_blank" rel="noopener noreferrer"><StaticImage src="../../../images/COVD logo.jpg" alt="COVD logo" height={70} /></a>
                    <a href="https://www.babo.co.uk/" target="_blank" rel="noopener noreferrer"><StaticImage src="../../../images/BABO logo.jpeg" alt="BABO logo" height={70} /></a>
                </div>
            </div>
            <div className={FooterStyles.lowerFooter}>
                <div className={FooterStyles.lowerContainer}>
                    <p>Website by <a href="https://goddard.digital" target="_blank">Goddard Digital</a></p>
                    <p>{year} © - Dr. C French, Consulting Optometrist</p>
                </div>
            </div>
        </footer>
    )
}

export default Footer