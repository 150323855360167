import React, { useState } from "react"
import { Link } from "gatsby"
import * as HeaderStyles from "./Header.module.css"
import { StaticImage } from "gatsby-plugin-image"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {faChevronDown, faChevronUp} from "@fortawesome/free-solid-svg-icons"

function Header() {
    const [navOpen, setNavOpen] = useState(false)
    const [subNavOpen, setSubNavOpen] = useState(false)

    return (
        <header>
            <div className={HeaderStyles.navContainer}>
                <Link className={HeaderStyles.logoLink} to="/">
                    <StaticImage
                        className={HeaderStyles.logo}
                        src="../../../images/logo-rect.png"
                        alt="Christian French Consulting Optometrist logo"
                        height={65}
                        placeholder="blurred"
                    />
                </Link>
                <div className={HeaderStyles.toggle} navopen={navOpen} onClick={() => setNavOpen(!navOpen)} onKeyPress={() => setNavOpen(!navOpen)} aria-label="Main menu" role="button" tabIndex={0}>
                    <div className={navOpen ? `${HeaderStyles.hamburgerOpen}` : `${HeaderStyles.hamburger}`}></div>
                </div>
                <nav className={navOpen ? `${HeaderStyles.nav} ${HeaderStyles.mobileNav}` : `${HeaderStyles.nav}`}>
                    <Link className={HeaderStyles.navItem} to="/">Home</Link>
                    <Link className={HeaderStyles.navItem} to="/about/">About</Link>
                    <div className={`${HeaderStyles.navItem} ${HeaderStyles.hoverItem}`} subNavOpen={subNavOpen} onClick={() => setSubNavOpen(!subNavOpen)} onMouseOver={() => setSubNavOpen(true)} onMouseOut={() => setSubNavOpen(false)}>Services<span className={HeaderStyles.subNavIcons}>{subNavOpen ? (<FontAwesomeIcon icon={faChevronUp} />) : (<FontAwesomeIcon icon={faChevronDown} />)}</span>
                        <div className={subNavOpen ? `${HeaderStyles.subNav} ${HeaderStyles.subNavOpen}` : `${HeaderStyles.subNav}`}>
                            <Link className={HeaderStyles.subNavItem} to="/services/eye-examinations/">Eye Examinations</Link>
                            <Link className={HeaderStyles.subNavItem} to="/services/contact-lenses/">Contact Lenses</Link>
                            <Link className={HeaderStyles.subNavItem} to="/services/neuro-optometry/">Neuro-optometry</Link>
                        </div>
                    </div>
                    <Link className={HeaderStyles.navItem} to="/contact/">Contact</Link>
                </nav>
            </div>
        </header>
    )
}

export default Header